
import { Component } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import { CreateInformation } from "@/@types/information";
import moment from "moment";
import FacilityModule from "@/store/facility";
import AuthModule from "@/store/auth";
import InformationService from "@/services/information";
import { deleteNullProp } from "@/utils/Util";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";

@Component({
  name: "NoticeSettingEdit",
  components: {
    HospitalHeader,
    Table,
    ManagementFooter,
    Button,
    FormSet,
  },
})
export default class NoticeSettingEdit extends CheckCommonLogin {
  private get hospitalId() {
    return FacilityModule.hospitalId;
  }
  private get me() {
    return AuthModule.me;
  }
  private informationId = this.$route.params.id;
  private showLabel = false;
  private loading = false;
  private informationList = [];
  private form: CreateInformation = {
    title: "",
    start_datetime: null,
    end_datetime: null,
    updated_by: this.me?.id,
  };

  private get dateTimeCheck() {
    const start = moment(this.form.start_datetime);
    const end = moment(this.form.end_datetime);
    return end.isAfter(start);
  }

  private back() {
    this.$router.push({ name: "NoticeSetting" });
  }

  private async mounted() {
    await this.getInformation();
  }

  private async getInformation() {
    try {
      this.loading = true;
      const res = await InformationService.get(this.hospitalId, this.informationId);
      this.form = res.data.data[0];
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }

  private async deleteInformation() {
    try {
      this.loading = true;
      await InformationService.delete(this.hospitalId, this.informationId);
      this.closeModal("delete");
      this.loading = false;
      this.$router.push({ name: "NoticeSetting" });
    } catch (error: any) {
      this.loading = false;
      this.showModal("deleteError");
      throw new Error(error);
    }
  }
  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private async submit() {
    try {
      this.loading = true;
      this.form.start_datetime = moment(this.form.start_datetime)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .toISOString();
      if (this.form.end_datetime) {
        this.form.end_datetime = moment(this.form.end_datetime)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .toISOString();
        if (!this.dateTimeCheck) {
          this.loading = false;

          this.showLabel = true;
          setTimeout(() => {
            this.showLabel = false;
          }, 5000);
          return;
        }
      } else {
        delete this.form.end_datetime;
      }
      const payload = deleteNullProp(this.form);
      await InformationService.update(this.hospitalId, this.$route.params.id, payload);
      this.$router.push({ name: "NoticeSetting" });
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      this.showModal("saveError");
      throw new Error(error);
    }
  }
}
